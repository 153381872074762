import React from 'react'
import {Link} from "react-router-dom";

function Success() {
  return (
      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div
                  className="login-modal modal-light"

              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content bg-dark border-1 border-success">
                    <div className="modal-body py-5">

                      <div className="md-body text-center">
                        <div className="icon-box evnet-icon mb-3">
                          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px"
                               fill="#000000">
                            <path
                                d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/>
                          </svg>
                        </div>
                        <div className="modal-logo-div mb-4">
                          <h1 className='text-primary mb-0'>
                            <b>Thank You!</b>
                          </h1>
                          <p className='text-white'>
                            Payment done Successfully
                          </p>
                          <p className='text-muted'>
                            You will be redirected to the home page shortly<br/>
                            or click here to return to home page
                          </p>
                        </div>
                        <div className='sb-div'>
                          <a href='#' className='btn btn-primary px-4'>Back to Home</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
  )
}

export default Success