import React from 'react'
import {Link} from "react-router-dom";

function EventList() {
  return (
      <div className='py-5'>

        <div
            className="modal login-modal modal-light fade"
            id="Event-Modal"
            tabIndex="-1"
            aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body py-5">
                <button id='login-modal-close-btn' type="button" className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"></button>
                <div className="md-body text-center">
                  <div className="icon-box evnet-icon mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                      <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"></path>
                    </svg>
                  </div>
                  <div className="modal-logo-div mb-4">
                    <h3 className='text-primary mb-0'>
                      <b>Event Name</b>
                    </h3>
                    <p className='text-muted'>
                      Date : 07/01/2025
                    </p>
                  </div>
                  <p className='mb-0'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='breadrumb mb-5'>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item text-primary">Event List</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row align-items-center mb-5">
              <div className="col-6"><h2 className="resto-item-title">Event List</h2></div>
              <div className="col-6">
                <div className="item-search-box position-relative"><input className="form-control"
                                                                          placeholder="Search.."/>
                  <button className="btn"><i className="fas fa-search text-white"></i></button>
                </div>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='event-list-div'>
                <div className='event-card mb-3'>
                  <div className='ec-date flex-colum'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                      <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/>
                    </svg>
                    <small className='mb-0'>
                      07-01-2025
                    </small>
                  </div>
                  <div className='ec-detail'>
                    <h3>Event Name</h3>
                    <p className='text-light mb-2'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the 1500s..
                    </p>

                  </div>
                  <div className='view-btn'>
                    <button className='btn p-0 text-primary'
                            data-bs-toggle="modal"
                            data-bs-target="#Event-Modal">
                      View Detail
                    </button>
                  </div>

                </div>


              </div>
              <div className='event-list-div'>
                <div className='event-card mb-3'>
                  <div className='ec-date flex-colum'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                      <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/>
                    </svg>
                    <small className='mb-0'>
                      07-01-2025
                    </small>
                  </div>
                  <div className='ec-detail'>
                    <h3>Event Name</h3>
                    <p className='text-light mb-2'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the 1500s..
                    </p>

                  </div>
                  <div className='view-btn'>
                    <button className='btn p-0 text-primary'
                            data-bs-toggle="modal"
                            data-bs-target="#Event-Modal">
                      View Detail
                    </button>
                  </div>

                </div>


              </div>
              <div className='event-list-div'>
                <div className='event-card mb-3'>
                  <div className='ec-date flex-colum'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                      <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/>
                    </svg>
                    <small className='mb-0'>
                      07-01-2025
                    </small>
                  </div>
                  <div className='ec-detail'>
                    <h3>Event Name</h3>
                    <p className='text-light mb-2'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the 1500s..
                    </p>

                  </div>
                  <div className='view-btn'>
                    <button className='btn p-0 text-primary'
                            data-bs-toggle="modal"
                            data-bs-target="#Event-Modal">
                      View Detail
                    </button>
                  </div>

                </div>


              </div>
              <div className='event-list-div'>
                <div className='event-card mb-3'>
                  <div className='ec-date flex-colum'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                      <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/>
                    </svg>
                    <small className='mb-0'>
                      07-01-2025
                    </small>
                  </div>
                  <div className='ec-detail'>
                    <h3>Event Name</h3>
                    <p className='text-light mb-2'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the 1500s..
                    </p>

                  </div>
                  <div className='view-btn'>
                    <button className='btn p-0 text-primary'
                            data-bs-toggle="modal"
                            data-bs-target="#Event-Modal">
                      View Detail
                    </button>
                  </div>

                </div>


              </div>
              <div className='event-list-div'>
                <div className='event-card mb-3'>
                  <div className='ec-date flex-colum'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                      <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/>
                    </svg>
                    <small className='mb-0'>
                      07-01-2025
                    </small>
                  </div>
                  <div className='ec-detail'>
                    <h3>Event Name</h3>
                    <p className='text-light mb-2'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the 1500s..
                    </p>

                  </div>
                  <div className='view-btn'>
                    <button className='btn p-0 text-primary'
                            data-bs-toggle="modal"
                            data-bs-target="#Event-Modal">
                      View Detail
                    </button>
                  </div>

                </div>


              </div>
            </div>

            <div className='col-md-12'>
              <div className="pagination-div text-center mt-4">
                <nav aria-label="...">
                  <ul className="pagination justify-content-center">
                    <li>
                      <span className="page-link">Previous</span>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">1</span>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default EventList