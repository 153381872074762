import React from 'react'
import {Link} from "react-router-dom";

function Failed() {
  return (
      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div
                  className="login-modal modal-light"

              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content bg-dark border-1 border-danger">
                    <div className="modal-body py-5">

                      <div className="md-body text-center">
                        <div className="icon-box evnet-icon bg-danger mb-3">
                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                               fill="#000000">
                            <path
                                d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"/>
                          </svg>
                        </div>
                        <div className="modal-logo-div mb-4">
                          <h1 className='text-danger mb-2'>
                            <b>Payment Failed</b>
                          </h1>
                          <p className='text-muted'>
                            Your transition has failed due to some<br/>

                            technical error. Please try again
                          </p>
                        </div>
                        <div className='sb-div'>
                          <a href='#' className='btn btn-primary px-4'>Make Payment</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
  )
}

export default Failed