import { createContext, useEffect, useState } from "react";
import { url } from "../helpers";
import { toast } from "react-toastify";

const userContext = createContext()

function getUserFromLocalStorage() {
    return localStorage.getItem('hussainiyah_user') ? JSON.parse(localStorage.getItem('hussainiyah_user')) : {
        type: 'guest',
        full_name: 'guest',
        message: {
            api_key: '780df365e7e49ec',
            api_secret: 'd6e94a18f55d1ce'
        }

    }
}

function UserContext(props) {
    const [user, setUser] = useState(getUserFromLocalStorage())
    const [vendorData, setVendorData] = useState([])
    const [activeSidebar, setActiveSidebar] = useState('')
    const [cart, setCart] = useState([])
    const [cartCount, setCartCount] = useState(0)
    const [load, setLoad] = useState(false)

    async function DeleteCart() {
        const response = await fetch(url+ 'resource/Cart/' + user?.message?.email,{
            headers:{
                Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`
            }, 
            method: 'DELETE'
        });

        if(response.ok == true){
            setLoad(false); 
            const data = await response.json();
            setCart([])
            setCartCount(0)
        }else{
            setLoad(false)
            toast.error("Internal Server Error!")
        }
    }
    async function fetchCurrentUserCart() {
        const response = await fetch(url + `resource/Cart/${user?.message?.email}`, {
            headers: {
                "Authorization": `token ${user?.message?.api_key}:${user?.message?.api_secret}`
            }
        })

        const data = await response.json()

        if (data?.data?.name) {
            console.log(data?.data)
            setCart(data?.data)
            let count = 0;
            if (data?.data?.qaari_zakreen_packages?.length > 0) {
                count = count + data?.data?.qaari_zakreen_packages?.length
            }

            if (data?.data?.restaurant_package?.length > 0) {
                data?.data?.restaurant_package?.map(item => {
                    count = count + item?.package_minimum_unit
                })
            }

            setCartCount(count)

        }
    }

    useEffect(() => {
        localStorage.setItem('hussainiyah_user', JSON.stringify(user))

        if (user?.full_name != 'guest') {
            fetchCurrentUserCart()
        }
    }, [user])

    async function AddToCart(type, incoming_data) {
        setLoad(true)
        let previous_restaurant_packages = []
        let previous_qaari_packages = []

        if (cart?.user) {
            previous_restaurant_packages = cart?.restaurant_package
            previous_qaari_packages = cart?.qaari_zakreen_packages
        }

        if (type == 'restaurant') {
            if (incoming_data?.order_quantity == 0 ) {
                let index_result = previous_restaurant_packages?.findIndex(item => (item?.restaurant_owner == incoming_data?.restaurant_owner) && (item?.package_name == incoming_data?.package_name))
                
                previous_restaurant_packages =previous_restaurant_packages?.filter((item,index) => index != index_result)
            
            } else {
                let previous_result = previous_restaurant_packages?.filter(item => (item?.restaurant_owner == incoming_data?.restaurant_owner) && (item?.package_name == incoming_data?.package_name))

                if (previous_result?.length > 0) {
                    previous_restaurant_packages = previous_restaurant_packages?.map(item => {
                        if ((item?.restaurant_owner == incoming_data?.restaurant_owner) && (item?.package_name == incoming_data?.package_name)) {
                            return incoming_data
                        } else {
                            return item
                        }
                    })
                } else {
                    previous_restaurant_packages.push(incoming_data)
                }
            }

        } else {
            if (incoming_data?.order_quantity == 0) {
                let index_result = previous_qaari_packages?.findIndex(item => (item?.qaari_zakreen == incoming_data?.qaari_zakreen) && (item?.package_name == incoming_data?.package_name))
                
                previous_qaari_packages =previous_qaari_packages?.filter((item,index) => index != index_result)
            
            } else {
                let previous_result = previous_qaari_packages?.filter(item => (item?.qaari_zakreen == incoming_data?.qaari_zakreen) && (item?.package_name == incoming_data?.package_name))

                if (previous_result?.length > 0) {
                    previous_qaari_packages = previous_qaari_packages?.map(item => {
                        if ((item?.qaari_zakreen == incoming_data?.qaari_zakreen) && (item?.package_name == incoming_data?.package_name)) {
                            return incoming_data
                        } else {
                            return item
                        }
                    })
                } else {
                    previous_qaari_packages.push(incoming_data)
                }
            }
        }

        console.log("previous_restaurant_packages", previous_restaurant_packages)


        let send_data = {
            "data": {
                "user": user?.message?.email,
                "qaari_zakreen_packages": previous_qaari_packages,
                "restaurant_package": previous_restaurant_packages
            }
        }

        let api_url = 'resource/Cart'

        if (cart?.user) {
            api_url = `resource/Cart/${user?.message?.email}`
        }

        const response = await fetch(url + api_url, {
            method: cart?.user ? 'PUT' : 'POST',
            headers: {
                "Authorization": `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
                "Content-Type": "application/json"
            },
            body: cart?.user ? JSON.stringify({
                "restaurant_package": previous_restaurant_packages,
                "qaari_zakreen_packages": previous_qaari_packages
            }) : JSON.stringify(send_data)
        })

        console.log(previous_restaurant_packages)

        const data = await response.json()
        
        if (data?.data){
            fetchCurrentUserCart()
        }

        setLoad(false)
    }

    return (
        <userContext.Provider value={{ AddToCart, cart, cartCount, user, setUser, load, setLoad, vendorData, setVendorData, activeSidebar, setActiveSidebar,DeleteCart}}>
            {props.children}
        </userContext.Provider>
    )
}

export { userContext, UserContext }