import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { node_image_url,url } from "../helpers";
import { userContext } from "../context/UserContext";
import Sidebar from "../components/Sidebar";

function UserProfile() {
  const { setLoad } = useContext(userContext);

  const [resname, setResName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const { user } = useContext(userContext);
  const [vendorData, setVendorData] = useState([]);
  const [coverPicture, setCoverPicture] = useState("");
  const [coverPictureSrc, setCoverPictureSrc] = useState("");

  useEffect(() => {
    setResName(user?.full_name);
    setEmail(user?.message?.email);
    setPhone(user?.message?.mobile_no);
  }, [user])

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    Customer Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5">
                  <div className="dark-card-header">
                    <h2 className="card-title mb-4">{user?.role_profile_name == 'huserprofile' ? 'Profile information' : 'Restaurant information'}</h2>
                  </div>
                  <form
                    onSubmit={(e) => {
                      // createRestaurant(e);
                    }}
                  >
                    <div className="dark-card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Name
                            </label>
                            <input
                              onChange={(e) => {
                                setResName(e.target.value);
                              }}
                              value={resname}
                              className="form-control lock-control"
                              type="text"
                              placeholder="Enter Restaurant Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Your Email</label>
                            <input
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              value={email}
                              className="form-control lock-control"
                              type="email"
                              placeholder="Enter Email"
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Mobile Number
                            </label>
                            <input
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              value={phone}
                              className="form-control lock-control"
                              type="text"
                              placeholder="Enter Mobile Number"
                              required
                              maxLength={8}
                              minLength={8}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row align-items-center">
                        <div className="col-md-3 order-1 order-sm-1 order-md-2">
                          <div className="form-group text-center text-sm-center text-md-end">
                            <button
                              type="submit"
                              className="btn btn-primary py-3 px-5"
                            >
                              {vendorData.length > 0 ? "Update" : "Create"}
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
