import React, { Fragment, useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { userContext } from "../context/UserContext";
import { baseUrl, node_image_url, url } from "../helpers";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Autocomplete } from "@react-google-maps/api";

function Home() {
  const { user, setLoad } = useContext(userContext);
  const [banners, setBanners] = useState([]);
  const [ocassions, setOcassions] = useState([]);
  const [resturent_data, setResturentData] = useState([]);
  const [QAARI, setQAARIData] = useState([]);

  async function fetchBanners() {
    setLoad(true);
    const response = await fetch(
      url + 'resource/Banners?fields=["*"]&limit_page_length=20&limit_start=0',
      {
        headers: {
          Authorization: `token 780df365e7e49ec:d6e94a18f55d1ce`,
        },
      }
    );
    if (response.ok == true) {
      const data = await response.json();
      setLoad(false);
      if (data?.data?.length > 0) {
        setBanners(data?.data);
      }
    }
  }

  async function fetchOcassions() {
    setLoad(true);
    const response = await fetch(
      url + 'resource/Ocassion?fields=["*"]&limit_page_length=20&limit_start=0',
      {
        headers: {
          Authorization: `token 780df365e7e49ec:d6e94a18f55d1ce`,
        },
      }
    );
    if (response.ok == true) {
      const data = await response.json();
      setLoad(false);

      if (data?.data?.length > 0) {
        setOcassions(data?.data);
      }
    }
  }

  async function fetchResturent() {
    setLoad(true);
    const response = await fetch(
      url +
      "resource/Restaurant?fields=[%22*%22]&limit_page_length=20&limit_start=0&filters=[[%22verification_status%22%2C%20%22%3D%22%2C%20%22Verified%22]]",
      {
        headers: {
          Authorization: "token 780df365e7e49ec:d6e94a18f55d1ce",
        },
      }
    );

    if (response.ok == true) {
      const data = await response.json();
      setLoad(false);
      setResturentData(data?.data);
    } else {
      toast.error("Internal server error");
    }
  }

  async function fetchQAARI() {
    setLoad(true);
    const response = await fetch(
      url +
      "resource/Qaari Zakreen?fields=[%22*%22]&limit_page_length=20&limit_start=0&filters=[[%22verification_status%22%2C%20%22%3D%22%2C%20%22Verified%22]]",
      {
        headers: {
          Authorization: "token 780df365e7e49ec:d6e94a18f55d1ce",
        },
      }
    );

    if (response.ok == true) {
      const data = await response.json();
      setLoad(false);
      setQAARIData(data?.data);
    } else {
      toast.error("Internal server error");
    }
  }

  useEffect(() => {
    fetchBanners();
    fetchOcassions();
    fetchResturent();
    fetchQAARI();
  }, []);

  return (
    <div>
      {/* <img src='/assets/images/image.png' /> */}
      <div className="home-slider-div">
        <OwlCarousel
          className="owl-theme"
          items={1}
          dots={true}
          loop={true}
          nav={false}
          margin={20}
        >
          {banners?.length > 0 &&
            banners?.map((item, index) => (
              <div key={index} className="item">
                <img
                  src={`${baseUrl}${item?.banner_image}`}
                  alt="img"
                  className="img-fluid"
                  width="100%"
                />
              </div>
            ))}
        </OwlCarousel>
      </div>


      <div
        className="modal login-modal modal-light fade"
        id="Event-Modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5">
              <button id='login-modal-close-btn' type="button" className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
              <div className="md-body text-center">
                <div className="icon-box evnet-icon mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#000000">
                    <path
                      d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"></path>
                  </svg>
                </div>
                <div className="modal-logo-div mb-4">
                  <h3 className='text-primary mb-0'>
                    <b>Event Name</b>
                  </h3>
                  <p className='text-muted'>
                    Date : 07/01/2025
                  </p>
                </div>
                <p className='mb-0'>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                  leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                  with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                  publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="occasions-section py-4 py-sm-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ts-div">
                <div className="section-title mb-2 mb-sm-2 mb-md-4">
                  <div className="row">
                    <div className="col-8">
                      <div className="sect-title-div">
                        <h3>Today Event</h3>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="sect-title-div text-end">
                        <Link to={`/EventList`} className="sect-view-all text-white">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='event-list-div'>
                  <div className='event-card mb-3'>
                    <div className='ec-date flex-colum'>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                        fill="#000000">
                        <path
                          d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
                      </svg>
                      {/* <small className='mb-0'>
                          07-01-2025
                        </small>*/}
                    </div>
                    <div className='ec-detail'>
                      <h3>Event Name</h3>
                      <p className='text-light mb-2'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s..
                      </p>

                    </div>
                    <div className='view-btn'>
                      <button className='btn p-0 text-primary'
                        data-bs-toggle="modal"
                        data-bs-target="#Event-Modal">
                        View Detail
                      </button>
                    </div>

                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="occasions-section py-4 py-sm-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ts-div">
                <div className="section-title mb-2 mb-sm-2 mb-md-4">
                  <div className="row">
                    <div className="col-8">
                      <div className="sect-title-div">
                        <h3>Occasions</h3>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="sect-title-div text-end">
                        <Link to={`/OccasionsList`} className="sect-view-all text-white">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carosuel-div">
                  <OwlCarousel
                    className="owl-theme"
                    items={3}
                    dots={false}
                    loop={false}
                    margin={20}
                    responsive={{
                      0: { items: 1, nav: false },
                      768: { items: 2, nav: false },
                      992: { nav: true },
                    }}
                    nav
                  >
                    {ocassions.length > 0 &&
                      ocassions.map((item, index) => (
                        <div key={index} className="item">
                          <div className="occasions-card position-relative">
                            <Link to={`/OccasionsZakereenList?name=${item?.name}`}>
                              <img
                                src={`${baseUrl}${item?.occasion_image}`}
                                alt="img"
                                className="img-fluid"
                                width="100%"
                              />
                              <h4>{item?.name}</h4>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="zakereen-section py-4 py-sm-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ts-div">
                <div className="section-title mb-2 mb-sm-2 mb-md-4">
                  <div className="row">
                    <div className="col-8">
                      <div className="sect-title-div">
                        <h3>Qari & Zakereen</h3>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="sect-title-div text-end">
                        <Link to={`/ZakiriList`} className="sect-view-all text-white">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carosuel-div">
                  <OwlCarousel
                    className="owl-theme"
                    items={6}
                    dots={false}
                    loop={false}
                    margin={20}
                    responsive={{
                      0: { items: 2, nav: false },
                      768: { items: 3, nav: false },
                      992: { nav: true },
                    }}
                    nav
                  >
                    {QAARI?.map((qaari, index) => (
                      <div key={index} className="item">
                        <div className="zakereen-card position-relative">
                          <Link to={`/ZakiriDetail?name=${qaari?.name}`}>
                            <div className="zakereen-img">
                              {qaari?.profile_picture ? (
                                <img
                                  src={qaari?.profile_picture}
                                  alt="img"
                                  className="img-fluid"
                                  width="100%"
                                />
                              ) : (
                                <img
                                  src="/assets/images/zakereen.png"
                                  alt="img"
                                  className="img-fluid"
                                  width="100%"
                                />
                              )}
                            </div>
                            <div className="zakereen-name">
                              <h4 className="text-white">{qaari?.qaari_name}</h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="restaurant-section py-4 py-sm-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ts-div">
                <div className="section-title mb-2 mb-sm-2 mb-md-4">
                  <div className="row">
                    <div className="col-8">
                      <div className="sect-title-div">
                        <h3>Delicious Restaurant</h3>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="sect-title-div text-end">
                        <Link to={`/RestaurantList`} className="sect-view-all text-white">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carosuel-div">
                  <OwlCarousel
                    className="owl-theme"
                    items={4}
                    dots={false}
                    loop={false}
                    margin={20}
                    responsive={{
                      0: { items: 2, nav: false },
                      768: { items: 3, nav: false },
                      992: { nav: true },
                    }}
                    nav
                  >
                    {resturent_data.map((res, index) => (
                      <div className="item" key={index}>
                        <div className="restaurant-card position-relative">
                          <Link to={`/RestaurantDetail?name=${res?.name}`}>
                            <div className="restaurant-img">
                              {res?.profile_picture ? (
                                <img
                                  src={`${res?.profile_picture}`}
                                  alt="img"
                                  className="img-fluid"
                                  width="100%"
                                />
                              ) : (
                                <img
                                  src="/assets/images/restaurant-img.png"
                                  alt="img"
                                  className="img-fluid"
                                  width="100%"
                                />
                              )}
                            </div>
                            <div className="restaurant-dtl">
                              <h5 className="text-white mb-1">
                                {res?.res_name}
                              </h5>
                              <p className="text-light mb-0">
                                Pizza, Pasta, Italian, Fast Food
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                    {/* <div className='item'>
                      <div className='restaurant-card position-relative'>
                        <a href='#'>
                          <div className='restaurant-img'>
                            <img src='/assets/images/restaurant-img.png' alt='img' className='img-fluid'
                              width='100%' />
                          </div>
                          <div className='restaurant-dtl'>
                            <h5 className='text-white mb-1'>
                              Restaurant Name
                            </h5>
                            <p className='text-light mb-0'>
                              Pizza, Pasta, Italian, Fast Food
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='restaurant-card position-relative'>
                        <a href='#'>
                          <div className='restaurant-img'>
                            <img src='/assets/images/restaurant-img.png' alt='img' className='img-fluid'
                              width='100%' />
                          </div>
                          <div className='restaurant-dtl'>
                            <h5 className='text-white mb-1'>
                              Restaurant Name
                            </h5>
                            <p className='text-light mb-0'>
                              Pizza, Pasta, Italian, Fast Food
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='restaurant-card position-relative'>
                        <a href='#'>
                          <div className='restaurant-img'>
                            <img src='/assets/images/restaurant-img.png' alt='img' className='img-fluid'
                              width='100%' />
                          </div>
                          <div className='restaurant-dtl'>
                            <h5 className='text-white mb-1'>
                              Restaurant Name
                            </h5>
                            <p className='text-light mb-0'>
                              Pizza, Pasta, Italian, Fast Food
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='restaurant-card position-relative'>
                        <a href='#'>
                          <div className='restaurant-img'>
                            <img src='/assets/images/restaurant-img.png' alt='img' className='img-fluid'
                              width='100%' />
                          </div>
                          <div className='restaurant-dtl'>
                            <h5 className='text-white mb-1'>
                              Restaurant Name
                            </h5>
                            <p className='text-light mb-0'>
                              Pizza, Pasta, Italian, Fast Food
                            </p>
                          </div>
                        </a>
                      </div>
                    </div> */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
