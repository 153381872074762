import React, { useContext, useEffect, useState } from "react";
import { url } from "../helpers";
import { userContext } from "../context/UserContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from '../components/Loader'
import { Autocomplete, useLoadScript } from '@react-google-maps/api';

function CheckOut() {
  const { user, setLoad, cart, DeleteCart } = useContext(userContext);
  const [customer, setCustomer] = useState([]);
  const [address, setAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [autocompleteRef, setAutoCompleteRef] = useState('')
  const [createAddressData, setCreateAddressData] = useState({
    addressTitle: '',
    address: "",
    city: '',
    country: '',
    pincode: ''
  })



  const cart_packages_price = cart?.restaurant_package
    ?.map((item) => item?.package_unit_price * item?.package_minimum_unit)
    .reduce((total, price) => total + price, 0);

  const cart_qaari_packages_price = cart?.qaari_zakreen_packages
    ?.map((item) => item?.package_unit_price * item?.package_minimum_unit)
    .reduce((total, price) => total + price, 0);

  const delevery_charge = 5;

  async function CreateCustomer() {
    const send_data = {
      data: {
        name: user?.message?.username,
        email: user?.message?.email,
        mobile_no: "9330668959", //usercontext missing field
        first_name: user?.message?.username,
        customer_name: user?.message?.username,
        custom_customer_email: user?.message?.email,
      },
    };

    const response = await fetch(url + "resource/Customer", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
      },
      method: "POST",
      body: JSON.stringify(send_data),
    });

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      toast.success("Customer Created Successfully");
    } else {
      setLoad(false);
      // toast.error("Internal Server Error");
    }
  }

  async function FetchCurrentCustomer() {
    setLoad(true);
    const response = await fetch(
      url +
      `resource/Customer?fields=["*"]&limit_page_length=20&limit_start=0&filters=[["custom_customer_email", "=", "${user?.message?.email}"]]`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      console.log(data);

      if (data?.data?.length == 0) {
        CreateCustomer();
      } else {
        setCustomer(data?.data[0]);
      }
    } else {
      setLoad(false);
      // toast.error("Internal Server Error!");
    }
  }

  async function FetchPreviousAddress() {
    setLoad(true);
    const response = await fetch(
      url +
      `resource/Address?fields=["*"]&limit_page_length=20&limit_start=0&filters=[["Dynamic Link", "link_name", "like", "${customer?.name}"]]`,
      {
        headers: {
          Authorization: `Token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    if (response.ok) {
      setLoad(false);
      const data = await response.json();
      console.log(data);
      setAddress(data?.data);
    } else {
      setLoad(false);
      // toast.error("Internal Server Error!");
    }
  }

  useEffect(() => {
    FetchCurrentCustomer();
  }, []);

  useEffect(() => {
    if (customer?.name) {
      FetchPreviousAddress();
    }
  }, [customer]);

  async function createOrder(e) {
    e.preventDefault();
    setLoad(true);
    let delivery_address = "";
    if (selectedAddress?.name) {
      delivery_address = selectedAddress?.name;
    } else {
      console.log(createAddressData)
      const send_data = {
        data: {
          name: createAddressData?.addressTitle,
          address_title: createAddressData?.addressTitle,
          address_type: "Shipping",
          address_line1: createAddressData?.address,
          address_line2: createAddressData?.address,
          city: createAddressData?.city?.length > 0 ? createAddressData?.city : '-',
          country: createAddressData?.country,
          pincode: createAddressData?.pincode?.length > 0 ? createAddressData?.pincode : '01',
          is_primary_address: 0,
          latitude: createAddressData?.latitude,
          longitude: createAddressData?.longitude,
          is_shipping_address: 0,
          links: [
            {
              link_doctype: "Customer",
              link_name: customer?.name,
              link_title: customer?.name,
            },
          ],
        },
      };

      const response = await fetch(url + "resource/Address", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        method: "POST",
        body: JSON.stringify(send_data),
      });

      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();
        console.log(data);

        if (data?.data?.name) {
          delivery_address = data?.data?.name;
        }
        // document.getElementById("request-sent-modal-trigger-btn").click();
      } else {
        setLoad(false);
        // toast.error("Internal Server Error!");
      }
    }

    if (delivery_address?.length > 0) {
      submitOrder(delivery_address);
    }
  }

  async function submitOrder(delivery_address) {
    let send_data = {
      data: {
        docstatus: 0,
        user: user?.message?.email,
        customer: customer?.name,
        shipping_address: delivery_address,
        delivery_status: "Not Delivered",
        payment_details: "Non Paid",
        order_date: new Date()
          .toLocaleString()
          .split(",")[0]
          .split("/")
          .reverse()
          .join("-"),
        qaari_zakreen_packages:
          cart?.qaari_zakreen_packages?.length > 0
            ? cart?.qaari_zakreen_packages?.map((item) => {
              return {
                package_name: item?.package_name,
                package_unit_price: item?.package_unit_price,
                package_description: item?.package_description,
                package_minimum_unit: item?.package_minimum_unit,
                qaari_zakreen: item?.qaari_zakreen,
              };
            })
            : [],
        restaurant_package:
          cart?.restaurant_package?.length > 0
            ? cart?.restaurant_package?.map((item) => {
              return {
                package_id: item?.package_id,
                package_name: item?.package_name,
                package_unit_price: item?.package_unit_price,
                package_description: item?.package_description,
                package_minimum_unit: item?.package_minimum_unit,
                restaurant_owner: item?.restaurant_owner,
              };
            })
            : cart?.restaurant_package,
      },
    };

    console.log("send_data", send_data);

    const response = await fetch(url + "resource/Order", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
      },
      method: "POST",
      body: JSON.stringify(send_data),
    });

    if (response.ok == true) {
      const data = await response.json();
      setLoad(false);
      console.log("order", data);
      if (data?.data?.name) {
        // DeleteCart()
        createOrderProgress(delivery_address, data?.data?.name, customer?.name);
      }
    } else {
      setLoad(false);
      // toast.error('Internal Server Error!')
    }
  }

  async function createOrderProgress(
    delivery_address,
    order_number,
    customer_name
  ) {
    let total_count =
      cart?.qaari_zakreen_packages?.length + cart?.restaurant_package?.length;

    if (cart?.restaurant_package?.length) {
      for (let i = 0; i < cart?.restaurant_package?.length; i++) {
        const item = cart?.restaurant_package[i];
        const send_data = {
          data: {
            "docstatus": 0,
            "shipping_address": delivery_address,
            "order_no": order_number,
            "status": "Pending Verification",
            "customer": customer_name,
            "type": "Restaurant",
            "doc_name": item?.restaurant_owner,
            "package_id": item?.package_id,
            "packagename": item?.package_name,
            "package_unit_price": item?.package_unit_price,
            "package_description": item?.package_description,
            "package_minimum_unit": item?.package_minimum_unit,
            "package_image": item?.pacakge_image,
            "order_qty": item?.order_quantity,
            "latitude": selectedAddress?.latitude ? selectedAddress?.latitude : createAddressData?.latitude,
            "longitude": selectedAddress?.longitude ? selectedAddress?.longitude : createAddressData?.longitude
          }
        }

        const response = await fetch(url + "resource/Order Progress", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
          body: JSON.stringify(send_data),
        });

        if (response.ok == true) {
          total_count = total_count - 1;
        }
      }
    }

    if (cart?.qaari_zakreen_packages?.length) {
      for (let i = 0; i < cart?.qaari_zakreen_packages?.length; i++) {
        const item = cart?.qaari_zakreen_packages[i];
        const send_data = {
          data: {
            "docstatus": 0,
            "shipping_address": delivery_address,
            "order_no": order_number,
            "status": "Pending Verification",
            "customer": customer_name,
            "type": "Qaari Zakreen",
            "doc_name": item?.qaari_zakreen,
            "package_id": item?.package_id,
            "packagename": item?.package_name,
            "package_unit_price": item?.package_unit_price,
            "package_description": item?.package_description,
            "package_minimum_unit": item?.package_minimum_unit,
            "package_image": item?.pacakge_image,
            "order_qty": 1
          }
        }
        const response = await fetch(url + "resource/Order Progress", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
          body: JSON.stringify(send_data),
        });

        if (response.ok == true) {
          total_count = total_count - 1;
        }
      }
    }

    if (total_count == 0) {
      DeleteCart();
      document.getElementById("request-sent-modal-trigger-btn").click();
      setLoad(false);
    }
  }


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCAvFWBSeW_UhPm6XpPHP8y5Fks8qap38I", // Replace with your API key
    libraries: ['places'],
  });


  if (!isLoaded) return <Loader />;
  return (
    <>
      <div className="py-5">
        <button
          id="request-sent-modal-trigger-btn"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#Request-Sent-Modal"
          className="d-none"
        >
          REQUEST ORDER MODAL
        </button>

        <div
          className="modal login-modal modal-light fade"
          id="Request-Sent-Modal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body py-5">
                <div className="md-body text-center">
                  <div className="icon-box mb-3">
                    <svg
                      width="100"
                      height="104"
                      viewBox="0 0 100 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M50 1.625C22.379 1.625 0 24.1719 0 52C0 79.8281 22.379 102.375 50 102.375C77.621 102.375 100 79.8281 100 52C100 24.1719 77.621 1.625 50 1.625ZM50 92.625C27.7621 92.625 9.67742 74.4047 9.67742 52C9.67742 29.5953 27.7621 11.375 50 11.375C72.2379 11.375 90.3226 29.5953 90.3226 52C90.3226 74.4047 72.2379 92.625 50 92.625ZM33.871 48.75C37.4395 48.75 40.3226 45.8453 40.3226 42.25C40.3226 38.6547 37.4395 35.75 33.871 35.75C30.3024 35.75 27.4194 38.6547 27.4194 42.25C27.4194 45.8453 30.3024 48.75 33.871 48.75ZM66.129 48.75C69.6976 48.75 72.5806 45.8453 72.5806 42.25C72.5806 38.6547 69.6976 35.75 66.129 35.75C62.5605 35.75 59.6774 38.6547 59.6774 42.25C59.6774 45.8453 62.5605 48.75 66.129 48.75ZM66.9355 63.4969C62.7419 68.575 56.5524 71.5 50 71.5C43.4476 71.5 37.2581 68.5953 33.0645 63.4969C31.3508 61.425 28.2863 61.1609 26.25 62.8672C24.1935 64.5938 23.9315 67.6609 25.625 69.7328C31.6734 77.0453 40.5645 81.2297 50 81.2297C59.4355 81.2297 68.3266 77.0453 74.375 69.7328C76.0887 67.6609 75.8064 64.5938 73.75 62.8672C71.7137 61.1609 68.6492 61.425 66.9355 63.4969Z"
                        fill="#198855"
                      />
                    </svg>
                  </div>
                  <div className="modal-logo-div mb-4">
                    <h4 className="text-success">
                      <b>The request has been sent</b>
                    </h4>
                  </div>
                  <p>
                    request has been sent for responses, and you will receive a
                    Acceptance or rejection will be announced soon
                  </p>
                  <p>Thank you for your patience!</p>
                  <div className="back-btn-div">
                    <a className="btn btn-primary py-3 px-4" href="/">
                      Back to Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <form
            onSubmit={(e) => {
              createOrder(e);
            }}
          >
            <div className="row">
              <div className="col-lg-8">
                <div className="account-right mb-3">
                  <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5">
                    {address?.length > 0 && (
                      <>
                        <div className="dark-card-header">
                          <h2 className="card-title mb-4">Saved Address</h2>
                        </div>

                        <div className="select-address-box mb-5">
                          {address?.map((item, index) => (
                            <div className="selectcheckbox" key={index}>
                              <input
                                type="radio"
                                name="selectaddress"
                                onChange={() => setSelectedAddress(item)}
                              />
                              <label>
                                {item?.address_title} , {item?.city} ,{" "}
                                {item?.country}
                                <br />
                                {item?.pincode}
                              </label>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    <div className="dark-card-body">
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Event *
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter event"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Delivery Date/Time
                            </label>
                            <input
                              className="form-control"
                              type="datetime-local"
                              placeholder="Delivery Date/Time"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Address Title *
                            </label>
                            <Autocomplete
                              onLoad={(autocomplete) => {
                                setAutoCompleteRef(autocomplete)
                              }}
                              onPlaceChanged={() => {
                                const place = autocompleteRef?.getPlace()

                                let country = "";
                                let city = "";
                                let pincode = "";

                                place.address_components.forEach((component) => {
                                  const types = component.types;
                                  if (types.includes("country")) {
                                    country = component.long_name;
                                  }
                                  if (
                                    types.includes("locality") ||
                                    types.includes("administrative_area_level_2")
                                  ) {
                                    city = component.long_name;
                                  }
                                  if (types.includes("postal_code")) {
                                    pincode = component.long_name;
                                  }
                                });

                                setCreateAddressData({
                                  addressTitle: place?.name,
                                  address: place?.formatted_address,
                                  country,
                                  city,
                                  pincode,
                                  latitude: place.geometry.location.lat(),
                                  longitude: place.geometry.location.lng()
                                })

                              }}
                            >
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter a location"
                              />
                            </Autocomplete>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="checkout-right">
                  <div className="checkout-details">
                    <h4 className="text-uppercase">Order summary</h4>
                    <div className="checkout-value">
                      <div className="row">
                        <div className="col-6">
                          <p>Subtotal</p>
                        </div>
                        <div className="col-6">
                          <p className="text-end">
                            KD {cart_packages_price + cart_qaari_packages_price}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Delivery Fee</p>
                        </div>
                        <div className="col-6">
                          <p className="text-end">KD {delevery_charge}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-6">
                          <h5>Total Amount</h5>
                        </div>
                        <div className="col-6">
                          <h5 className="text-end">
                            KD{" "}
                            {cart_packages_price +
                              cart_qaari_packages_price +
                              delevery_charge}
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            className="btn mt-3 btn-primary text-uppercase w-100 py-3"
                            type="submit"
                          >
                            Request order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CheckOut;
